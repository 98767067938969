<template>
  <view ref="el"></view>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";
import "artalk/dist/Artalk.css";
import Artalk from "artalk";

export default defineComponent({
  setup() {
    const el = ref(null);
    const route = useRoute();

    let artalk = Artalk;
    let path = "";
    if (route != null) {
      path = route.path;
    }

    onMounted(() => {
      artalk = Artalk.init({
        el: el.value,
        pageKey: path,
        pageTitle: `${document.title}`,
        server: "https://ljg.cool/artalk/",
        site: "my portal",
      });
    });

    onUnmounted(() => {
      artalk.destroy();
    });

    return { artalk, el };
  },
});
</script>
