<template>
  <div id="app">
    <!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <FMPage></FMPage>
    <MsgBoard></MsgBoard>
    <a id="ipc" href="https://beian.miit.gov.cn/" target="_blank"
      >京ICP备2020048366号-1</a
    >
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import FMPage from "./components/music-player/FMPage";
import MsgBoard from "./components/input/msg-board/ArtalkMsgBoard";
import axios from "axios";

export default {
  name: "App",
  components: {
    // HelloWorld
    FMPage,
    MsgBoard,
  },
  mounted() {
    console.log("test mounted");
    const url = "//ljg.cool/backend";
    axios
      .get(url)
      .then((res) => {
        console.log(res);
      })
      .catch((er) => {
        console.log(er);
      });
  },
};
</script>

<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#app {
  margin-top: 60px;
}

#ipc {
  margin: 0;
  padding: 0;
  bottom: 0;
  line-height: 100%;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  color: #999;
}
</style>
