<template>
  <aplayer
    autoplay
    showLrc
    float
    listMaxHeight="500px"
    :music="this.audio[0]"
    :list="audio"
  />
</template>

<script>
import Aplayer from "vue3-aplayer";
// import playlist from "../../assets/playlist/wedding.json";
import playlist from "../../assets/playlist/emo.json";

// Vue-APlayer 默认会在控制台打印出当前的版本标识，如果你想要禁用它，可以将 disableVersionBadge 设为 true。
Aplayer.disableVersionBadge = true;

export default {
  name: "FMPage",
  data() {
    return {
      // 音频列表，版权问题怎么搞？
      audio: playlist,
    };
  },
  components: {
    Aplayer,
  },
};
// TODO: 音乐播放器宽度自适应
// TODO: js 写个脚本，给分享连接，直接输出 playlist
</script>

<style scoped>
.aplayer {
  max-width: 700px;
  width: 80%;
  border-radius: 10px;
  text-align: center;
  left: 25%;
  margin-top: 60px;
  margin-bottom: 30px;
  transform: translate(-50%, -50%);
}
</style>
